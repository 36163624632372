import { useState } from 'react';

import Image from 'next/image';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  CardActionArea,
  IconButton,
  Menu,
  Popover,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
// import SearchIcon from '@mui/icons-material/Search';

import MBLogo from './MBLogo';
import Notifications from './Notifications';
import SearchAll from './SearchAll';

import { NextLinkComposed } from './Link';

import useAuth from '../src/auth.js';


const TopNav = () => {
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('sm'),
    { defaultMatches: true }
  );
  const { user } = useAuth();
  const [anchorNotifications, setAnchorNotifications] = useState(null);

  const notificationsMenuOpen = Boolean(anchorNotifications);

  const openNotifications = (event) => {
    setAnchorNotifications(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorNotifications(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {isMobile ?
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonBase
          component={NextLinkComposed}
          to={{ pathname: `/` }}
        >
          <Image
            src="/hub/img/logos/logo-72x72.png"
            width={56}
            height={56}
            quality={100}
            alt="MB"
          />
        </ButtonBase>

        {user ?
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton
              color="inherit"
              onClick={openNotifications}
              size="large"
              sx={{
                mr: 1
              }}
            >
              <Badge badgeContent={user.unread_notifications} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Menu
              anchorEl={anchorNotifications}
              open={notificationsMenuOpen}
              onClose={closeNotifications}
              onClick={closeNotifications}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  // mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 68,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                  py: 0
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{

              }}
              MenuListProps={{
                sx: {
                  py: 0
                }
              }}
            >
              <Notifications closeNotifications={closeNotifications} />
            </Menu>

            <CardActionArea
              component={NextLinkComposed}
              sx={{
                borderRadius: '50%',
              }}
              to={{
                pathname: '/profile/'
              }}
            >
              <Avatar
                alt={user.first_name}
                src={user.picture}
                sx={{
                  backgroundColor: 'primary.light',
                  color: '#fff',
                }}
              >{user.initials}</Avatar>
            </CardActionArea>
          </Box> : <Skeleton width={160} height={40} />
        }
      </Toolbar> :

      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ButtonBase
          component={NextLinkComposed}
          to={{ pathname: `/` }}
          sx={{ pt: 1 }}
        >
          <MBLogo />
        </ButtonBase>

        <Box sx={{ width: 400 }}>
          <SearchAll />
        </Box>

        {user ? 
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            minWidth: 320
          }}>
            <IconButton
              color="inherit"
              onClick={openNotifications}
              size="large"
              sx={{
                mr: 2
              }}
            >
              <Badge badgeContent={user.unread_notifications} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Menu
              anchorEl={anchorNotifications}
              open={notificationsMenuOpen}
              onClose={closeNotifications}
              onClick={closeNotifications}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  // mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 18,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                  py: 0
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{

              }}
              MenuListProps={{
                sx: {
                  py: 0
                }
              }}
            >
              <Notifications closeNotifications={closeNotifications} />
            </Menu>

            <Tooltip title={`${user.first_name} ${user.last_name}`}>
              <CardActionArea
                component={NextLinkComposed}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  py: 0.5,
                  pl: 0.5,
                  pr: 2.5,
                  border: 1,
                  borderRadius: 6,
                  borderColor: 'primary.light',
                  width: 'auto'
                }}
                to={{
                  pathname: '/profile/'
                }}
              >
                <Avatar
                  alt={user.first_name}
                  src={user.picture}
                  sx={{
                    backgroundColor: 'primary.light',
                    color: '#fff',
                  }}
                >{user.initials}</Avatar>

                <Typography variant="subtitle1" sx={{ ml: 2 }}>{user.business}</Typography>
              </CardActionArea>
            </Tooltip>
          </Box> : <Skeleton width={320} height={40} />
        }
      </Toolbar>}
    </AppBar>
  );
}

export default TopNav;
