import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import {
  Box,
  Drawer,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';

import Contacts from './Contacts';


const drawerWidth = 320;


const ContactDrawer = () => {
  const router = useRouter();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (["0", "1"].includes(router?.query?.activeTab)) {
      setTabIndex(parseInt(router.query.activeTab))
    }
  }, [router])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Box
        sx={{
          width: drawerWidth,
          color: 'white',
          minHeight: 'calc(100vh - 64px)',
          bgcolor: 'primary.dark',
        }}
        id="scrollableDiv"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.light',
            },
          }}
        >
          <Tab
            label="Leads"
            sx={{
              minWidth: 72,
              color: 'white',
              '&.Mui-selected': {
                color: 'secondary.main',
              }
            }}
          />
          <Tab
            label="Clients"
            sx={{
              minWidth: 72,
              color: 'white',
              '&.Mui-selected': {
                color: 'secondary.main',
              }
            }}
          />
        </Tabs>
        {tabIndex == 0 && <Contacts contactType="leads" />}
        {tabIndex == 1 && <Contacts contactType="clients"/>}
      </Box>
    </Drawer>
  );
}

export default ContactDrawer;
