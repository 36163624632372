import { useState } from 'react';

import { useRouter } from 'next/router';
import Image from 'next/image';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import useSWRInfinite from 'swr/infinite';

import Loader from './Loader';
import { NextLinkComposed } from './Link';
import useDebounce from '../src/useDebounce';


const Contacts = ({ contactType }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const router = useRouter();

  const activeContact = router.asPath.split('?')[0]

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 500);

  const getKey = (index) => {
    var url = `/api/v2/${contactType}/?page=${index + 1}`
    if (debouncedSearch) {
      url += `&search=${debouncedSearch}`
    }

    return url
  }

  const { data, error, size, setSize } = useSWRInfinite(getKey);
  // TODO handle error

  const results = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const dataLength = data ? data.length : 0;
  const isLoading = size !== dataLength;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < 20);

  return (
    <Box
      sx={{
        bgcolor: 'primary.dark',
        color: 'white',
        pb: isMobile ? 8 : 0
      }}>
      <Box
        sx={{
          pt: 2,
          px: 2,
          display: 'flex'
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          size="small"
          sx={{
            borderRadius: 8,
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& label.Mui-focused': {
              color: 'white',
            },
            '& .MuiInputLabel-root': {
              color: '#bbbbbb'
            },
            '& .MuiInput-root': {
              color: '#bbbbbb'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#bbbbbb',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'secondary.main',
              },
            }
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <List>
        {results.map((contact) => (
          <ListItemButton
            key={contact.id}
            component={NextLinkComposed}
            to={{
              pathname: `/contacts/${contact.id}/`,
              query: {
                activeTab: contactType === 'leads' ? 0 : 1
              }
            }}
            selected={activeContact == `/contacts/${contact.id}/`}
            sx={{
              '&:hover': {
                bgcolor: 'primary.main',
              },
              '&.Mui-selected': {
                bgcolor: '#2d4f60',
              },
              '&.Mui-selected:hover': {
                bgcolor: 'primary.main'
              },
              '&.Mui-focusVisible': {
                bgcolor: 'primary.main'
              },
            }}
          >
            <ListItemAvatar>
              <Avatar src={contact.user.picture}>{contact.user.initials}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={contact.user.name}
              secondary={contact.last_event_time}
              sx={{
                '& .MuiListItemText-secondary': {
                  color: '#aaaaaa'
                }
              }}
            />
          </ListItemButton>
        ))}
      </List>

      {isEmpty && !isLoading &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body1" sx={{ mb: 3 }}>No { contactType } yet ...</Typography>
          <Image width={140} height={160} src="/hub/img/illustrations/quote-request-approved-dark-mode.png" alt="Send estimates" />
          <Typography variant="body2" sx={{ mt: 2 }}>Start sending estimates to opportunities</Typography>
        </Box>
      }

      {dataLength === 0 && isLoading && <Loader count={10} height={48} />}
      {dataLength !== 0 &&
        <Box display="flex" justifyContent="center" py={2}>
          {!isReachingEnd && !isLoading &&
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSize(size + 1)}
            >Load More</Button>
          }
          {isLoading && <CircularProgress color="secondary" />}
        </Box>
      }
    </Box>
  )
}

export default Contacts;
