import React from 'react';

import {
  Avatar,
  Box,
  Stack,
  Typography,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HelpIcon from '@mui/icons-material/Help';
import StarsIcon from '@mui/icons-material/Stars';


const UserCard = ({ user, type }) => {

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      alignItems="center"
      bgcolor="#EFECE8"
      p={2}
    >
      <Avatar
        src={user.picture}
        sx={{
          width: 120,
          height: 120,
          bgcolor: 'primary.light',
        }}
      >{user.initials}</Avatar>
      <Box
        sx={{
          textAlign: "center",
          mt: 2
        }}
      >
        <Typography>{ user.name }</Typography>
        {type && <Box color="secondary.main" width="100%">{ type }</Box>}
      </Box>

      <Box
        sx={{
          width: "100%",
          p: 2,
          fontSize: 14,
          color: "text.secondary"
        }}
      >
        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
          <span>{user.car_count}</span>
          <DirectionsCarIcon fontSize="small" />
          <span>Cars</span>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
          <span>{user.job_count}</span>
          <HelpIcon fontSize="small" />
          <span>Jobs</span>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
          <span>{user.review_count}</span>
          <StarsIcon fontSize="small" />
          <span>Reviews</span>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
          <span>{user.job_completed_count}</span>
          <CheckCircleIcon fontSize="small" />
          <span>Completed</span>
        </Stack>
      </Box>
    </Box>
  );
}

export default UserCard;
